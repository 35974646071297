import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import { Form, Button } from "react-bootstrap";
// import loadable from '@loadable/component'
import GenerateLink from "../common/site/generate-link"
import $ from 'jquery'
import { navigate, useMatch } from "@reach/router"
import SearchResultInputBox from "../predictive-search/SearchResultBoxHeaderSales"
import SearchResultInputBoxLet from "../predictive-search/SearchResultBoxHeaderLet"
import SearchResultInputBoxNewHome from "../predictive-search/SearchResultBoxHeaderNewHome"
import { removeSpecialChar, hyphenToCaptilize, QueryList } from "../utils/property-search"
// import SearchLoqateAddress from '../forms/elements/address-search-header'


// const SearchLoqateAddress = loadable(() => import('../forms/elements/address-search-header'))


const MegaMenu = (props) => {

  // let url = "/";

  // useEffect(() => {
  // $(".search-btn").click(function() {
  //   var searcValue = $(".address").val()
  //   url = "/property/for-sale/"
  //   if(searcValue !== "") {
  //       url += "in-"+searcValue+"/"
  //   } else {
  //       url += "in-kent/"
  //   }
  //   navigate(url);
  // })
  // })
  // const [test, setTest] = useEffect(false);

  function search(val) {
    let url = "/property/for-sale/";
    var head_val = $(".head-search-input-box-sales").val()
    var head_val_let = $(".head-search-input-box-let").val()
    var head_val_new = $(".head-search-input-box-newhome").val()

    const main_page = typeof window !== 'undefined' ? window.location : ''
    var page = ''
    if (main_page.pathname) {
      page = main_page.pathname.split("/")[1]
    }

    localStorage.setItem('searchValueFullName', val ? val : 'chipping-ognar-essex');


    var searcValue = val.split(', ').join('-').replace(/ /g, "-").replace(/'/g, "").toLowerCase();
    if (searcValue == "") {
      localStorage.removeItem('searchValue');
      localStorage.removeItem('searchValueFullName');
    }
    if (props.Search_Type == "Residential_Sales") {
      url = "/property/for-sale/"
      if (searcValue !== "") {
        url += "in-" + searcValue + "/"
      } else {
        localStorage.setItem('searchValue', 'chipping-ognar-essex');
        url += "in-chipping-ognar-essex/radius-15-miles/"
      }
      if((searcValue == "" && head_val == "") || (searcValue && head_val != "")) {
        navigate(url)
      }
      else {
        $(".select-area-error-head-sales").show().delay(4000).fadeOut();
      }
    }
    else if (props.Search_Type == "Residential_Lettings") {
      url = "/property/to-rent/"
      if (searcValue !== "") {
        url += "in-" + searcValue + "/"
      } else {
          localStorage.setItem('searchValue', 'chipping-ognar-essex');
          url += "in-chipping-ognar-essex/radius-15-miles/"
      }
      if((searcValue == "" && head_val_let == "") || (searcValue && head_val_let != "")) {
        navigate(url)
      }
      else {
        $(".select-area-error-head-let").show().delay(4000).fadeOut();
      }
    }
    else if (props.Search_Type == "New_Homes") {
      url = "/property/new-homes/for-sale/"
      if (searcValue !== "") {
        url += "in-" + searcValue + "/"
      } else {
        url += ""
      }
      if((searcValue == "" && head_val_new == "") || (searcValue && head_val_new != "")) {
        navigate(url)
      }
      else {
        $(".select-area-error-head-newhome").show().delay(4000).fadeOut();
      }
    }

    localStorage.setItem('navindex', 'home');
    // navigate(url)
    // window.location.href = url;
    if (page == "property") {
    window.location.reload();
    }
  }


  function valuation() {
    navigate(`/sell-your-property/property-valuation`);
  }
  const [areaVal, setAreaVal] = useState('')

  const queryList = QueryList();

  return (
    <div className={`menu-dropdown ${props.data?.Add_Sub_Menus?.length == "1" ? "single-col" : ""}`}>
      {props.data?.In_Submenu_Show_Search &&
        <div className={`postcode_form search_form_${props.val}`}>
          <div className="h3">{props.data?.Submenu_Search_Title}</div>
          {props.Search_Type ?
            <div className="form header-predictive-search">
              {props.Search_Type == "Residential_Sales" ? 
              <SearchResultInputBox
                value={areaVal !== 'chipping-ognar-essex' ? hyphenToCaptilize(areaVal) : ''}
                type={props.Search_Type}
                setValue={(searchValue, isSelected) => {
                  // refine(removeSpecialChar(searchValue)) 
                  if (isSelected) {
                    localStorage.setItem('searchValue', removeSpecialChar(searchValue));
                  } else {
                    localStorage.removeItem('searchValue');
                  }
                  setAreaVal(searchValue)
                }}
                placeHolder={`Choose town or area`}
                areaList={queryList.areaList}
                /> : props.Search_Type == "Residential_Lettings" ? 
                <SearchResultInputBoxLet
                  value={areaVal !== 'chipping-ognar-essex' ? hyphenToCaptilize(areaVal) : ''}
                  type={props.Search_Type}
                  setValue={(searchValue, isSelected) => {
                    // refine(removeSpecialChar(searchValue)) 
                    if (isSelected) {
                      localStorage.setItem('searchValue', removeSpecialChar(searchValue));
                    } else {
                      localStorage.removeItem('searchValue');
                    }
                    setAreaVal(searchValue)
                  }}
                  placeHolder={`Choose town or area`}
                  areaList={queryList.areaList}
                /> : props.Search_Type == "New_Homes" ? 
                <SearchResultInputBoxNewHome
                  value={areaVal !== 'chipping-ognar-essex' ? hyphenToCaptilize(areaVal) : ''}
                  type={props.Search_Type}
                  setValue={(searchValue, isSelected) => {
                    // refine(removeSpecialChar(searchValue)) 
                    if (isSelected) {
                      localStorage.setItem('searchValue', removeSpecialChar(searchValue));
                    } else {
                      localStorage.removeItem('searchValue');
                    }
                    setAreaVal(searchValue)
                  }}
                  placeHolder={`Choose town or area`}
                  areaList={queryList.areaList}
                /> : ''}
              <Button variant="primary" className="search-btn" onClick={() => {
                search(areaVal)
              }}>Start Now<i className="icon-arrow"></i></Button>
            </div> :
            <div className="form header-predictive-search">
              <Button variant="secondary" className="search-btn val-cta" onClick={() => { valuation() }}>In-person Valuation<i className="icon-arrow head"></i></Button>
              <Button variant="primary" className="search-btn val-cta" onClick={() => { valuation() }}>Instant Valuation<i className="icon-arrow"></i></Button>
            </div>
          }
        </div>
      }
      <div className="dropdown-link-list">
        {props.data && props.data.Add_Sub_Menus.map((item, i) => (
          <div className="dropdown-box">
            {item.Title &&
              <span>{item.Title}</span>
            }
            <ul>
              {item.Add_here.map((menus, i) => {
                return (
                  <li>
                    <GenerateLink link={menus.Submenu_Link}>{menus.Submenu_Label}</GenerateLink>
                  </li>
                )
              })}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MegaMenu;
