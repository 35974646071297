import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { Row, Col } from "react-bootstrap"
import $ from "jquery"

import {
  VALUATION_PAGE_URL,
  BRANCHES_PAGE_URL,
  ABOUT_PAGE_URL,
  PROPERTY_SERVICES_PAGE_URL,
  AREA_GUIDE_PAGE_URL,
  LATEST_NEWS_PAGE_URL,
  PROPERTY_MANAGEMENT_PAGE_URL,
  TESTIMONIALS_PAGE_URL,
  MORTGAGES_PAGE_URL,
} from "../../common/site/constants"

const PopularSearchDynamic = props => {
  //Get values from refine search
  const [ptype, setPtype] = useState(false)
  const [searchbed, setsearchbed] = useState(false)

  var searchaction = ""
  var URL = ""
  var ptypeURL = ""
  var bedURL = ""
  var bedtypeText = ""
  var area = "Chipping Ognar, Essex"
  var typeText = "Property"
  var searcharea = ""
  var searchareaText = ""
  var slug_text = ""
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }
  if (props.searchtype == "sales") {
    searchaction = "for Sale"
    URL = "/property/for-sale/"
  }
  if (props.searchtype == "lettings") {
    searchaction = "to Rent"
    URL = "/property/to-rent/"
  }
  if (props.searchtype == "newhomesales") {
    searchaction = "for Sale"
    URL = "/property/new-homes/for-sale/"
    typeText = "New homes"
  }
  if (props.searchtype == "newhomerentals") {
    searchaction = "to Rent"
    URL = "/property/new-homes/to-rent/"
    typeText = "New Property"
  }
  if (props.searchPtype) {
    ptypeURL = "/type-" + props.searchPtype
    typeText = capitalizeFirstLetter(props.searchPtype).replace(
      "Buy-to-let-investments",
      "Buy to let investments"
    )
  }
  if (props.searchBedroomfield) {
    bedtypeText = props.searchBedroomfield + " Bedroom " + typeText
    bedURL = "/" + props.searchBedroomfield + "-and-more-bedrooms"
  }
  //console.log("$$$$", props.Searcharea && props.Searcharea.areas !='undefined' && props.Searcharea.areas)
  if (props.Searcharea && props.Searcharea != "undefined") {
    searcharea = props.Searcharea.replace("in ", "").replace(",", "")
    searchareaText = props.Searcharea.replace(/%20/g, " ").replace("in ", "")
    slug_text = searcharea.replace(/\s/g, "-")
  }
  return (
    <Row>
      {props.searchBedroomfield ? (
        <Col md={6} lg={3} className="properties-info">
          <ul>
            <li>
              <a
                onClick={() => {
                  localStorage.setItem("searchValue", "barkingside-ilford-essex")
                  localStorage.setItem("searchValueFull", "barkingside-ilford-essex")
                }}
                className="footer_scroll_top"
                href={`${URL}in-barkingside-ilford-essex${ptypeURL}${bedURL}/radius-3-miles/`}
              >
                {bedtypeText} {searchaction} in Barkingside
              </a>
            </li>
            <li>
              <a
                onClick={() => {
                  localStorage.setItem("searchValue", "billericay-essex")
                  localStorage.setItem("searchValueFull", "billericay-essex")
                }}
                className="footer_scroll_top"
                href={`${URL}in-billericay-essex${ptypeURL}${bedURL}/radius-3-miles/`}
              >
                {bedtypeText} {searchaction} in Billericay
              </a>
            </li>
            <li>
              <a
                onClick={() => {
                  localStorage.setItem("searchValue", "chadwell-heath-essex")
                  localStorage.setItem("searchValueFull", "chadwell-heath-essex")
                }}
                className="footer_scroll_top"
                href={`${URL}in-chadwell-heath-essex${ptypeURL}${bedURL}/radius-3-miles/`}
              >
                {bedtypeText} {searchaction} in Chadwell Heath
              </a>
            </li>
            <li>
              <a
                onClick={() => {
                  localStorage.setItem("searchValue", "chigwell-essex")
                  localStorage.setItem("searchValueFull", "chigwell-essex")
                }}
                className="footer_scroll_top"
                href={`${URL}in-chigwell-essex${ptypeURL}${bedURL}/radius-3-miles/`}
              >
                {bedtypeText} {searchaction} in Chigwell
              </a>
            </li>
            <li>
              <a
                onClick={() => {
                  localStorage.setItem("searchValue", "hornchurch-essex")
                  localStorage.setItem("searchValueFull", "hornchurch-essex")
                }}
                className="footer_scroll_top"
                href={`${URL}in-hornchurch-essex${ptypeURL}${bedURL}/radius-3-miles/`}
              >
                {bedtypeText} {searchaction} in Hornchurch
              </a>
            </li>
            <li>
              <a
                onClick={() => {
                  localStorage.setItem("searchValue", "woodford-bridge-essex")
                  localStorage.setItem("searchValueFull", "woodford-bridge-essex")
                }}
                className="footer_scroll_top"
                href={`${URL}in-woodford-bridge-essex${ptypeURL}${bedURL}/radius-3-miles/`}
              >
                {bedtypeText} {searchaction} in Woodford
              </a>
            </li>
          </ul>
        </Col>
      ) : (
        <Col md={6} lg={3} className="properties-info">
          <ul>
            <li>
              <a
                onClick={() => {
                  localStorage.setItem("searchValue", "barkingside-ilford-essex")
                  localStorage.setItem("searchValueFull", "barkingside-ilford-essex")
                }}
                className="footer_scroll_top"
                href={`${URL}in-barkingside-ilford-essex${ptypeURL}`}
              >
                {typeText} {searchaction} in Barkingside
              </a>
            </li>
            <li>
              <a
                onClick={() => {
                  localStorage.setItem("searchValue", "billericay-essex")
                  localStorage.setItem("searchValueFull", "billericay-essex")
                }}
                className="footer_scroll_top"
                href={`${URL}in-billericay-essex${ptypeURL}`}
              >
                {typeText} {searchaction} in Billericay
              </a>
            </li>
            <li>
              <a
                onClick={() => {
                  localStorage.setItem("searchValue", "chadwell-heath-essex")
                  localStorage.setItem("searchValueFull", "chadwell-heath-essex")
                }}
                className="footer_scroll_top"
                href={`${URL}in-chadwell-heath-essex${ptypeURL}`}
              >
                {typeText} {searchaction} in Chadwell Heath
              </a>
            </li>
            <li>
              <a
                onClick={() => {
                  localStorage.setItem("searchValue", "chigwell-essex")
                  localStorage.setItem("searchValueFull", "chigwell-essex")
                }}
                className="footer_scroll_top"
                href={`${URL}in-chigwell-essex${ptypeURL}`}
              >
                {typeText} {searchaction} in Chigwell
              </a>
            </li>
            <li>
              <a
                onClick={() => {
                  localStorage.setItem("searchValue", "hornchurch-essex")
                  localStorage.setItem("searchValueFull", "hornchurch-essex")
                }}
                className="footer_scroll_top"
                href={`${URL}in-hornchurch-essex${ptypeURL}`}
              >
                {typeText} {searchaction} in Hornchurch
              </a>
            </li>
            <li>
              <a
                onClick={() => {
                  localStorage.setItem("searchValue", "woodford-bridge-essex")
                  localStorage.setItem("searchValueFull", "woodford-bridge-essex")
                }}
                className="footer_scroll_top"
                href={`${URL}in-woodford-bridge-essex${ptypeURL}}`}
              >
                {typeText} {searchaction} in Woodford
              </a>
            </li>
          </ul>
        </Col>
      )}

      {searcharea ? (
        <Col md={6} lg={3} className="properties-info">
          {props.searchBedroomfield ? (
            <ul>
              <li>
                <a
                  onClick={() => {
                    localStorage.setItem("searchValue", "barkingside-ilford-essex")
                    localStorage.setItem("searchValueFull", "barkingside-ilford-essex")
                  }}
                  className="footer_scroll_top"
                  href={`${URL}in-barkingside-ilford-essex${ptypeURL}`}
                >
                  {typeText} {searchaction} in Barkingside
                </a>
              </li>
              <li>
                <a
                  onClick={() => {
                    localStorage.setItem("searchValue", "billericay-essex")
                    localStorage.setItem("searchValueFull", "billericay-essex")
                  }}
                  className="footer_scroll_top"
                  href={`${URL}in-billericay-essex${ptypeURL}`}
                >
                  {typeText} {searchaction} in Billericay
                </a>
              </li>
              <li>
                <a
                  onClick={() => {
                    localStorage.setItem("searchValue", "chadwell-heath-essex")
                    localStorage.setItem("searchValueFull", "chadwell-heath-essex")
                  }}
                  className="footer_scroll_top"
                  href={`${URL}in-chadwell-heath-essex${ptypeURL}`}
                >
                  {typeText} {searchaction} in Chadwell Heath
                </a>
              </li>
              <li>
                <a
                  onClick={() => {
                    localStorage.setItem("searchValue", "chigwell-essex")
                    localStorage.setItem("searchValueFull", "chigwell-essex")
                  }}
                  className="footer_scroll_top"
                  href={`${URL}in-chigwell-essex${ptypeURL}`}
                >
                  {typeText} {searchaction} in Chigwell
                </a>
              </li>
              <li>
                <a
                  onClick={() => {
                    localStorage.setItem("searchValue", "hornchurch-essex")
                    localStorage.setItem("searchValueFull", "hornchurch-essex")
                  }}
                  className="footer_scroll_top"
                  href={`${URL}in-hornchurch-essex${ptypeURL}`}
                >
                  {typeText} {searchaction} in Hornchurch
                </a>
              </li>
              <li>
                <a
                  onClick={() => {
                    localStorage.setItem("searchValue", "woodford-bridge-essex")
                    localStorage.setItem("searchValueFull", "woodford-bridge-essex")
                  }}
                  className="footer_scroll_top"
                  href={`${URL}in-woodford-bridge-essex${ptypeURL}}`}
                >
                  {typeText} {searchaction} in Woodford
                </a>
              </li>
            </ul>
          ) : props.searchPtype ? (
            <ul>
              <li>
                <a
                  onClick={() => {
                    localStorage.setItem("searchValue", slug_text)
                    localStorage.setItem("searchValueFull", slug_text)
                  }}
                  className="footer_scroll_top"
                  href={`${URL}in-${searcharea.replace(
                    /\s/g,
                    "-"
                  ).toLowerCase()}/1-and-more-bedrooms/radius-3-miles/`}
                >
                  1 Bedroom Property {searchaction} in{" "}
                  {capitalizeFirstLetter(searchareaText)}
                </a>
              </li>
              <li>
                <a
                  onClick={() => {
                    localStorage.setItem("searchValue", slug_text)
                    localStorage.setItem("searchValueFull", slug_text)
                  }}
                  className="footer_scroll_top"
                  href={`${URL}in-${searcharea.replace(
                    /\s/g,
                    "-"
                  ).toLowerCase()}/2-and-more-bedrooms/radius-3-miles/`}
                >
                  2 Bedroom Property {searchaction} in{" "}
                  {capitalizeFirstLetter(searchareaText)}
                </a>
              </li>
              <li>
                <a
                  onClick={() => {
                    localStorage.setItem("searchValue", slug_text)
                    localStorage.setItem("searchValueFull", slug_text)
                  }}
                  className="footer_scroll_top"
                  href={`${URL}in-${searcharea.replace(
                    /\s/g,
                    "-"
                  ).toLowerCase()}/3-and-more-bedrooms/radius-3-miles/`}
                >
                  3 Bedroom Property {searchaction} in{" "}
                  {capitalizeFirstLetter(searchareaText)}
                </a>
              </li>
              <li>
                <a
                  onClick={() => {
                    localStorage.setItem("searchValue", slug_text)
                    localStorage.setItem("searchValueFull", slug_text)
                  }}
                  className="footer_scroll_top"
                  href={`${URL}in-${searcharea.replace(
                    /\s/g,
                    "-"
                  ).toLowerCase()}/4-and-more-bedrooms/radius-3-miles/`}
                >
                  4 Bedroom Property {searchaction} in{" "}
                  {capitalizeFirstLetter(searchareaText)}
                </a>
              </li>
              <li>
                <a
                  onClick={() => {
                    localStorage.setItem("searchValue", slug_text)
                    localStorage.setItem("searchValueFull", slug_text)
                  }}
                  className="footer_scroll_top"
                  href={`${URL}in-${searcharea.replace(
                    /\s/g,
                    "-"
                  ).toLowerCase()}/5-and-more-bedrooms/radius-3-miles/`}
                >
                  5 Bedroom Property {searchaction} in{" "}
                  {capitalizeFirstLetter(searchareaText)}
                </a>
              </li>
              <li>
                <a
                  onClick={() => {
                    localStorage.setItem("searchValue", slug_text)
                    localStorage.setItem("searchValueFull", slug_text)
                  }}
                  className="footer_scroll_top"
                  href={`${URL}in-${searcharea.replace(
                    /\s/g,
                    "-"
                  ).toLowerCase()}/6-and-more-bedrooms/radius-3-miles/`}
                >
                  6 Bedroom Property {searchaction} in{" "}
                  {capitalizeFirstLetter(searchareaText)}
                </a>
              </li>
            </ul>
          ) : (
            <ul>
              <li>
                <a
                  onClick={() => {
                    localStorage.setItem("searchValue", slug_text)
                    localStorage.setItem("searchValueFull", slug_text)
                  }}
                  className="footer_scroll_top"
                  href={`${URL}in-${searcharea.replace(
                    /\s/g,
                    "-"
                  ).toLowerCase()}/1-and-more-bedrooms/radius-3-miles/`}
                >
                  1 Bedroom Property {searchaction} in{" "}
                  {capitalizeFirstLetter(searchareaText)}
                </a>
              </li>
              <li>
                <a
                  onClick={() => {
                    localStorage.setItem("searchValue", slug_text)
                    localStorage.setItem("searchValueFull", slug_text)
                  }}
                  className="footer_scroll_top"
                  href={`${URL}in-${searcharea.replace(
                    /\s/g,
                    "-"
                  ).toLowerCase()}/2-and-more-bedrooms/radius-3-miles/`}
                >
                  2 Bedroom Property {searchaction} in{" "}
                  {capitalizeFirstLetter(searchareaText)}
                </a>
              </li>
              <li>
                <a
                  onClick={() => {
                    localStorage.setItem("searchValue", slug_text)
                    localStorage.setItem("searchValueFull", slug_text)
                  }}
                  className="footer_scroll_top"
                  href={`${URL}in-${searcharea.replace(
                    /\s/g,
                    "-"
                  ).toLowerCase()}/3-and-more-bedrooms/radius-3-miles/`}
                >
                  3 Bedroom Property {searchaction} in{" "}
                  {capitalizeFirstLetter(searchareaText)}
                </a>
              </li>
              <li>
                <a
                  onClick={() => {
                    localStorage.setItem("searchValue", slug_text)
                    localStorage.setItem("searchValueFull", slug_text)
                  }}
                  className="footer_scroll_top"
                  href={`${URL}in-${searcharea.replace(
                    /\s/g,
                    "-"
                  ).toLowerCase()}/4-and-more-bedrooms/radius-3-miles/`}
                >
                  4 Bedroom Property {searchaction} in{" "}
                  {capitalizeFirstLetter(searchareaText)}
                </a>
              </li>
              <li>
                <a
                  onClick={() => {
                    localStorage.setItem("searchValue", slug_text)
                    localStorage.setItem("searchValueFull", slug_text)
                  }}
                  className="footer_scroll_top"
                  href={`${URL}in-${searcharea.replace(
                    /\s/g,
                    "-"
                  ).toLowerCase()}/5-and-more-bedrooms/radius-3-miles/`}
                >
                  5 Bedroom Property {searchaction} in{" "}
                  {capitalizeFirstLetter(searchareaText)}
                </a>
              </li>
              <li>
                <a
                  onClick={() => {
                    localStorage.setItem("searchValue", slug_text)
                    localStorage.setItem("searchValueFull", slug_text)
                  }}
                  className="footer_scroll_top"
                  href={`${URL}in-${searcharea.replace(
                    /\s/g,
                    "-"
                  ).toLowerCase()}/6-and-more-bedrooms/radius-3-miles/`}
                >
                  6 Bedroom Property {searchaction} in{" "}
                  {capitalizeFirstLetter(searchareaText)}
                </a>
              </li>
            </ul>
          )}
        </Col>
      ) : (
        <Col md={6} lg={3} className="properties-info">
          <ul>
            <li>
              <a
                onClick={() => {
                  localStorage.setItem("searchValue", slug_text)
                  localStorage.setItem("searchValueFull", slug_text)
                }}
                className="footer_scroll_top"
                href={`${URL}in-${searcharea.replace(
                  /\s/g,
                  "-"
                )}/1-and-more-bedrooms/radius-3-miles/`}
              >
                1 Bedroom Property {searchaction} in{" "}
                {capitalizeFirstLetter(searchareaText)}
              </a>
            </li>
            <li>
              <a
                onClick={() => {
                  localStorage.setItem("searchValue", slug_text)
                  localStorage.setItem("searchValueFull", slug_text)
                }}
                className="footer_scroll_top"
                href={`${URL}in-${searcharea.replace(
                  /\s/g,
                  "-"
                )}/2-and-more-bedrooms/radius-3-miles/`}
              >
                2 Bedroom Property {searchaction} in{" "}
                {capitalizeFirstLetter(searchareaText)}
              </a>
            </li>
            <li>
              <a
                onClick={() => {
                  localStorage.setItem("searchValue", searcharea)
                  localStorage.setItem("searchValueFull", searcharea)
                }}
                className="footer_scroll_top"
                href={`${URL}in-${searcharea.replace(
                  /\s/g,
                  "-"
                )}/3-and-more-bedrooms/radius-3-miles/`}
              >
                3 Bedroom Property {searchaction} in{" "}
                {capitalizeFirstLetter(searchareaText)}
              </a>
            </li>
            <li>
              <a
                onClick={() => {
                  localStorage.setItem("searchValue", slug_text)
                  localStorage.setItem("searchValueFull", slug_text)
                }}
                className="footer_scroll_top"
                href={`${URL}in-${searcharea.replace(
                  /\s/g,
                  "-"
                )}/4-and-more-bedrooms/radius-3-miles/`}
              >
                4 Bedroom Property {searchaction} in{" "}
                {capitalizeFirstLetter(searchareaText)}
              </a>
            </li>
            <li>
              <a
                onClick={() => {
                  localStorage.setItem("searchValue", slug_text)
                  localStorage.setItem("searchValueFull", slug_text)
                }}
                className="footer_scroll_top"
                href={`${URL}in-${searcharea.replace(
                  /\s/g,
                  "-"
                )}/5-and-more-bedrooms/radius-3-miles/`}
              >
                5 Bedroom Property {searchaction} in{" "}
                {capitalizeFirstLetter(searchareaText)}
              </a>
            </li>
            <li>
              <a
                onClick={() => {
                  localStorage.setItem("searchValue", slug_text)
                  localStorage.setItem("searchValueFull", slug_text)
                }}
                className="footer_scroll_top"
                href={`${URL}in-${searcharea.replace(
                  /\s/g,
                  "-"
                )}/6-and-more-bedrooms/radius-3-miles/`}
              >
                6 Bedroom Property {searchaction} in{" "}
                {capitalizeFirstLetter(searchareaText)}
              </a>
            </li>
          </ul>
        </Col>
      )}

      <Col md={6} lg={3} className="properties-info">
        <ul>
          <li>
            <a
              onClick={() => {
                localStorage.setItem("searchValue", "chipping-ognar-essex")
                localStorage.setItem("searchValueFull", "chipping-ognar-essex")
              }}
              className="footer_scroll_top"
              href={`${URL}in-chipping-ognar-essex/type-apartment/radius-15-miles/`}
            >
              Apartments {searchaction} in {area}
            </a>
          </li>
          <li>
            <a
              onClick={() => {
                localStorage.setItem("searchValue", "chipping-ognar-essex")
                localStorage.setItem("searchValueFull", "chipping-ognar-essex")
              }}
              className="footer_scroll_top"
              href={`${URL}in-chipping-ognar-essex/type-flats/radius-15-miles/`}
            >
              Flats {searchaction} in {area}
            </a>
          </li>
          <li>
            <a
              onClick={() => {
                localStorage.setItem("searchValue", "chipping-ognar-essex")
                localStorage.setItem("searchValueFull", "chipping-ognar-essex")
              }}
              className="footer_scroll_top"
              href={`${URL}in-chipping-ognar-essex/type-house/radius-15-miles/`}
            >
              Houses {searchaction} in {area}
            </a>
          </li>
          <li>
            <a
              onClick={() => {
                localStorage.setItem("searchValue", "chipping-ognar-essex")
                localStorage.setItem("searchValueFull", "chipping-ognar-essex")
              }}
              className="footer_scroll_top"
              href={`${URL}in-chipping-ognar-essex/type-bungalow/radius-15-miles/`}
            >
              Bungalows {searchaction} in {area}
            </a>
          </li>
          <li>
            <a
              className="footer_scroll_top"
              href={`${MORTGAGES_PAGE_URL.alias}`}
            >
              Mortgage brokers in {capitalizeFirstLetter(searchareaText)}
            </a>
          </li>
          <li>
            <a
              className="footer_scroll_top"
              href={`${TESTIMONIALS_PAGE_URL.alias}`}
            >
              Testimonials
            </a>
          </li>
        </ul>
      </Col>

      <Col md={6} lg={3} className="properties-info">
        <ul>
          <li>
            <a
              className="footer_scroll_top"
              href={`${AREA_GUIDE_PAGE_URL.alias}`}
            >
              {capitalizeFirstLetter(searchareaText)} Area Guides
            </a>
          </li>
          <li>
            <a
              className="footer_scroll_top"
              href={`${BRANCHES_PAGE_URL.alias}/`}
            >
              Estate Agents in {capitalizeFirstLetter(searchareaText)}
            </a>
          </li>
          <li>
            <a
              className="footer_scroll_top"
              href={`${VALUATION_PAGE_URL.alias}`}
            >
              Property valuation in {capitalizeFirstLetter(searchareaText)}
            </a>
          </li>
          <li>
            <a
              className="footer_scroll_top"
              href={`${PROPERTY_MANAGEMENT_PAGE_URL.alias}`}
            >
              Property management in {capitalizeFirstLetter(searchareaText)}
            </a>
          </li>
          <li>
            <a className="footer_scroll_top" href={`${ABOUT_PAGE_URL.alias}`}>
              About Douglas Allen estate agents
            </a>
          </li>
          <li>
            <a
              className="footer_scroll_top"
              href={`${LATEST_NEWS_PAGE_URL.alias}/`}
            >
              Latest Property News
            </a>
          </li>
        </ul>
      </Col>
    </Row>
  )
}

export default PopularSearchDynamic
